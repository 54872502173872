import { template as template_0b6caaed8c8743b2a3617eb76a0c9105 } from "@ember/template-compiler";
const FKText = template_0b6caaed8c8743b2a3617eb76a0c9105(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
