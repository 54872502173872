import { template as template_d354018eefe746eda70c08d906233165 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_d354018eefe746eda70c08d906233165(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
